// src/js/auth.ts

// Firebase Imports
import { app, db } from "../../config/firebaseConfig";
import {
  getAuth,
  setPersistence,
  browserSessionPersistence,
  GoogleAuthProvider,
  signInWithRedirect,
  getRedirectResult,
  signOut,
  User,
  Auth,
} from "firebase/auth";

// Firestore Imports
import {
  getDoc,
  doc,
  setDoc,
  DocumentReference,
  DocumentSnapshot,
} from "firebase/firestore";

// Webhooks Imports
import { triggerSignUpWorkflow } from "./authWebhookSignup";

// Import auth functions
import { sendMessageToContentScript } from "./authPostMessage";

// Initialize Firebase services
const auth: Auth = getAuth(app);
const googleProvider: GoogleAuthProvider = new GoogleAuthProvider();

/**
 * Interface representing the structure of user data stored in Firestore.
 */
interface UserData {
  email: string;
  firstName: string;
  lastName: string;
  lastLogin: Date;
}

/**
 * Interface representing the payload sent to the webhook.
 */
interface WebhookPayload {
  email: string;
}

/**
 * Initializes Firebase Authentication with session persistence.
 */
export async function initializeAuth(): Promise<void> {
  try {
    await setPersistence(auth, browserSessionPersistence);
    console.log("Firebase authentication persistence set to session.");
  } catch (error) {
    console.error("Error setting authentication persistence:", error);
  }
}

/**
 * Initiates Google Sign-In using redirect.
 */
export function signInWithGoogle(): void {
  signInWithRedirect(auth, googleProvider);
}

/**
 * Handles the result of the authentication redirect.
 */
export async function handleRedirectResult(): Promise<void> {
  try {
    const result = await getRedirectResult(auth);
    if (result && result.user) {
      const currentUser: User = result.user;
      const needsWorkflow = await handleSignIn(currentUser);
      if (needsWorkflow) {
        // You might want to emit an event or use a global state here
        // For simplicity, this example assumes the React component handles it
        // Hence, no further action here
      }
      console.log("User signed in via redirect.");
    }
  } catch (error) {
    console.error("Error processing sign-in result:", error);
  }
}

/**
 * Saves user data to Firestore.
 * @param {User} currentUser - The authenticated user object.
 */
export async function saveUserData(currentUser: User): Promise<void> {
  const userData: UserData = prepareUserData(currentUser);
  const userDocRef: DocumentReference = doc(
    db,
    "users",
    currentUser.email as string
  );

  try {
    await setDoc(userDocRef, userData, { merge: true });
    console.log("User data saved to Firestore.");
  } catch (error) {
    console.error("Error saving user data to Firestore:", error);
  }
}

/**
 * Prepares user data for Firestore.
 * @param {User} currentUser - The authenticated user object.
 * @returns {UserData} - The user data to be stored.
 */
function prepareUserData(currentUser: User): UserData {
  const displayName: string = currentUser.displayName || "";
  const names: string[] = displayName.split(" ");
  const firstName: string = names[0] || "";
  const lastName: string = names.slice(1).join(" ") || "";
  const lastLogin: Date = new Date();

  return {
    email: currentUser.email as string,
    firstName,
    lastName,
    lastLogin,
  };
}

/**
 * Checks if the user's `folderData` document exists in Firestore.
 * @param {User} currentUser - The authenticated user object.
 * @returns {Promise<boolean>} - True if the document exists, false otherwise.
 */
export async function checkUserFilesDocumentExists(
  currentUser: User
): Promise<boolean> {
  const userFilesDocRef: DocumentReference = doc(
    db,
    `users/${currentUser.email}/userFiles/folderData`
  );
  try {
    const userFilesDocSnap: DocumentSnapshot = await getDoc(userFilesDocRef);
    return userFilesDocSnap.exists();
  } catch (error) {
    console.error("Error checking user files document existence:", error);
    return false;
  }
}

/**
 * Handles post-sign-in actions, such as triggering sign-up workflows.
 * @param {User} currentUser - The authenticated user object.
 * @returns {Promise<boolean>} - Returns true if sign-up workflow is needed, else false.
 */
export async function handleSignIn(currentUser: User): Promise<boolean> {
  try {
    await saveUserData(currentUser);
    const documentExists: boolean = await checkUserFilesDocumentExists(
      currentUser
    );

    if (!documentExists) {
      console.log("User needs to complete sign-up workflow.");
      return true; // Indicate that workflow is needed
    } else {
      console.log("User already has `folderData`, no sign-up workflow needed.");
      return false; // No workflow needed
    }

    // Send user email to content script if applicable
    sendMessageToContentScript({
      type: "USER_EMAIL",
      email: currentUser.email || "",
    });
  } catch (error) {
    console.error("Error handling sign-in:", error);
    return false; // On error, assume workflow is not needed
  }
}

/**
 * Logs out the current user.
 */
export async function logoutUser(): Promise<void> {
  try {
    await signOut(auth);
    console.log("User logged out successfully.");
  } catch (error) {
    console.error("Error logging out:", error);
  }
}
